import React from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { MetadataJSONSchema } from "~/api/metadata";
import { ArtworkImage } from "./ArtworkImage";
import { ArtworkCarousel } from "./ArtworkCarousel";

const MediaVideo = styled("video")({
    display: "block",
    width: "100%",
    height: "100%",
    maxHeight: 670,
    objectFit: "contain",
    position: "relative",
});
const MediaDiv = MediaVideo.withComponent("div");

export type MetadataWithArtworkMedia = {
    title: MetadataJSONSchema["title"];
    phygitalData: MetadataJSONSchema["phygitalData"];
    properties: Pick<
        MetadataJSONSchema["properties"],
        | "preview"
        | "media"
        | "previewFileType"
        | "fileType"
        | "image"
        | "embeddedPreview"
        | "hasAudio"
        | "description"
        | "vimeoId"
    >;
};

export function getSourceAndFileType(metadata?: MetadataWithArtworkMedia) {
    if (
        metadata?.properties?.preview &&
        metadata?.properties?.previewFileType
    ) {
        return {
            src: metadata.properties.preview.description,
            fileType: metadata.properties.previewFileType.description,
        };
    }
    if (metadata?.properties?.media && metadata?.properties?.fileType) {
        return {
            src: metadata.properties.media.description,
            fileType: metadata.properties.fileType.description,
        };
    }
    if (
        metadata?.properties?.image?.description &&
        metadata.properties?.fileType?.description
    ) {
        return {
            src: metadata?.properties?.image?.description,
            fileType: metadata.properties?.fileType?.description,
        };
    }
    return {
        src: "",
        fileType: "",
    };
}

const PlantPrayerEmbeddedPreview = `
  <iframe
    src="https://player.vimeo.com/video/577786599?badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&muted=1"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    style="width:100%;height:100%;"
    title="PLANT PRAYER 720.mp4">
  </iframe>
  <script src="https://player.vimeo.com/api/player.js"></script>
`;
const PurityEmbeddedPreview = `
  <iframe
    src="https://player.vimeo.com/video/578153605?badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&muted=1"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    style="width:100%;height:100%;"
    title="PURITY MAIN">
  </iframe>
  <script src="https://player.vimeo.com/api/player.js"></script>
`;
const MonkWalkEmbeddedPreview = `
  <iframe
    src="https://player.vimeo.com/video/577782774?badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&muted=1"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    style="width:100%;height:100%;"
    title="MONK WALK 720.mp4">
  </iframe>
  <script src="https://player.vimeo.com/api/player.js"></script>
`;
const ShrineAscensionEmbeddedPreview = `
  <iframe
    src="https://player.vimeo.com/video/577786602?badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&muted=1"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    style="width:100%;height:100%;"
    title="SHRINE ASCENSION 720.mp4">
  </iframe>
  <script src="https://player.vimeo.com/api/player.js"></script>
`;

export function getEmbeddedPreview(metadata: MetadataWithArtworkMedia) {
    if (metadata?.title === "Plant Prayer") {
        return PlantPrayerEmbeddedPreview;
    }
    if (metadata?.title === "Purity") {
        return PurityEmbeddedPreview;
    }
    if (metadata?.title === "Monk Walk") {
        return MonkWalkEmbeddedPreview;
    }
    if (metadata?.title === "Shrine Ascension") {
        return ShrineAscensionEmbeddedPreview;
    }

    if (metadata?.properties?.vimeoId) {
        return `
            <iframe
                src="https://player.vimeo.com/video/${metadata?.properties?.vimeoId?.description}?badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&muted=1"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="width:100%;height:100%;"
            >
            </iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
        `;
    }

    return metadata?.properties?.embeddedPreview?.description as string;
}

export function getPoster(metadata: MetadataWithArtworkMedia) {
    return String(metadata?.properties?.fileType?.description).includes("image")
        ? (metadata?.properties?.media?.description as string)
        : undefined;
}

export function videoSourceConvert(src: string, quality: string) {
    if (src.startsWith("blob:")) {
        return src;
    }
    const normalizedTokenURI = String(src)
        .replace("ipfs://", "https://liveart.infura-ipfs.io/ipfs/")
        .replace("https://ipfs.infura.io", "https://liveart.infura-ipfs.io");
    const cdn = new URL(
        process.env.NEXT_PUBLIC_MEDIA_OPTIMIZATION_SRV ||
            "https://5i354cjbesvqm56ejg32zmldfm0emibn.lambda-url.us-east-1.on.aws/",
    );
    const params = new URLSearchParams();
    params.append("token_url", normalizedTokenURI);
    params.append("quality", quality);
    params.append("redirect", "true");
    params.append(
        "placeholder",
        process.env.NEXT_PUBLIC_VIDEO_OPTIMIZATION_PLACEHOLDER ||
            "https://liveartx.imgix.net/QmVqLMNun4D1T1fTGnSb2Gz4nvkDY2sai5X9QrquHjGmAF?w=500&h=500",
    );

    return `${cdn.toString()}?${params.toString()}`;
}

function convertToImgxUrl(imgUrl: string) {
    const [, cid] = imgUrl.match(/\/ipfs\/([\d\w]+)/) || [];

    if (cid) {
        return `https://liveartx.imgix.net/${cid}?w=500&h=500`;
    }

    return imgUrl;
}

const preventEventPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
};

export type ArtworkMediaProps = {
    metadata?: MetadataWithArtworkMedia;
    width?: number | string;
    // eslint-disable-next-line react/no-unused-prop-types
    optimized?: boolean;
    quality?: "low" | "medium" | "high";
    showEmbeddedPreview?: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    optimizedImageStyle?: React.CSSProperties;
};

export function blurredBackground(metadata) {
    const { src, fileType } = getSourceAndFileType(metadata);

    if (String(fileType).includes("video")) {
        return "";
    }

    const [, cid] = String(src).match(/\/ipfs\/([\d\w]+)/) || [];

    if (cid) {
        return `https://liveartx.imgix.net/${cid}?w=200&h=200&blur=30`;
    }
    return "";
}

export function ArtworkMedia({
    metadata,
    width,
    quality = "high",
    showEmbeddedPreview = true,
}: React.PropsWithChildren<ArtworkMediaProps>) {
    const { src, fileType } = getSourceAndFileType(metadata);

    if (!metadata) {
        return null;
    }

    const embeddedPreview = getEmbeddedPreview(metadata);
    if (embeddedPreview && showEmbeddedPreview) {
        return (
            <MediaDiv
                sx={{
                    height: "670px",
                    maxHeight: "100%",

                    "@media (max-width: 375px)": {
                        height: "435px",
                    },

                    "& > div": {
                        padding: "0 !important",
                        width: "100%",
                        height: "100%",
                    },
                }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: embeddedPreview as string,
                }}
            />
        );
    }

    if (!src) {
        return null;
    }

    const createVideo = (videoSrc) => {
        return (
            <MediaVideo
                key={videoSrc}
                data-testid="artwork-media-video"
                playsInline
                width={width}
                preload="metadata"
                loop
                autoPlay
                muted
                poster={getPoster(metadata)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
            >
                <source
                    src={String(
                        `${videoSourceConvert(
                            videoSrc.toString(),
                            quality,
                        )}#t=0.001`,
                    )} // Force poster load on iOS
                />
            </MediaVideo>
        );
    };

    const getMainArtwork = () => {
        return String(fileType).includes("video") ? (
            createVideo(src)
        ) : (
            <ArtworkImage
                src={convertToImgxUrl(String(src))}
                alt={String(metadata.properties.description.description)}
            />
        );
    };

    const phisicalImages =
        metadata.phygitalData?.map((pData) => {
            return {
                src: pData.phygitalImage,
                type: pData.phygitalType,
            };
        }) || [];

    const images = phisicalImages.map((pImage) =>
        String(pImage.type).includes("video") ? (
            createVideo(pImage.src)
        ) : (
            <ArtworkImage
                key={pImage.src}
                src={String(pImage.src)}
                alt={String(metadata.properties.description.description)}
            />
        ),
    );

    return (
        // eslint-disable-next-line @next/next/no-img-element
        <>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            {phisicalImages.length ? (
                <Box onClick={preventEventPropagation}>
                    <ArtworkCarousel>
                        {getMainArtwork()}
                        <>{images}</>
                    </ArtworkCarousel>
                </Box>
            ) : (
                getMainArtwork()
            )}
        </>
    );
}
