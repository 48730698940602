import React from "react";
import { styled } from "@mui/system";

const MediaImage = styled("img")({
    display: "block",
    width: "100%",
    height: "100%",
    maxHeight: 670,
    objectFit: "contain",
    position: "relative",
});

export type ArtworkImageProps = {
    src?: string;
    alt?: string;
};

export function ArtworkImage({
    src,
    alt,
}: React.PropsWithChildren<ArtworkImageProps>) {
    return (
        <MediaImage
            src={String(src)}
            alt={String(alt)}
            data-testid="artwork-image"
        />
    );
}
